import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function Privacy() {

  useEffect(() => {
    document.title = "KTR Studio - Privacy Poilcy"
  }, []);
  return (
    <>
      <Header />
      <section className=" w-full flex flex-col items-center bg-gray-50">
        <div className="flex flex-col md:w-4/6 lg:w-2/4 text-justify  p-10 text-gray-700">
          <h1 className="text-3xl mb-28 mx-auto">Privacy Policy of <br /> KTR Solutions</h1>
          <p className="mb-12">KTR Solutions GmbH operates the ktr.studio website, which provides the
            SERVICE.</p>
          <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of
            Personal Information if anyone decided to use our Service, the KTR-Solutions website. If you choose to use our
            Service, you agree to the collection and use of information in relation with this policy. The Personal
            Information that we collect are used for providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have
            the same meanings as in our Terms and Conditions, which is accessible at ktr.studio/tos, unless
            otherwise defined in this Privacy Policy.</p>

          <p className=" text-3xl mb-8 mt-10 flex flex-col items-center">Information Collection and Use</p>

          <p>For a better experience while using our Service, we may require you to provide us with certain personally
            identifiable information, including but not limited to your name, phone number, and postal address. The
            information that we collect will be used to contact or identify you.</p>

          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Log Data</p>

          <p>We want to inform you that whenever you visit our Service, we collect information that your browser
            sends to us
            that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol
            ("IP")
            address, browser version, pages of our Service that you visit, the time and date of your visit, the time
            spent on
            those pages, and other statistics.</p>


          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Cookies</p>

          <p className="mb-3">Cookies are files with small amount of data that is commonly used an anonymous unique
            identifier. These are sent
            to your browser from the website that you visit and are stored on your computer’s hard drive. Our website uses
            these "cookies" to collection information and to improve our Service. You have the
            option to
            either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you
            choose to
            refuse our cookies, you may not be able to use some portions of our Service.</p>

          <p>For more general information on cookies, please read <a href="https://www.cookieconsent.com/what-are-cookies/"
            className="text-blue-500">"What Are Cookies"</a>.</p>

          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Local Storage</p>
          <p>Our website utilises the local storage functionallity to store Data about the users session to be
            able to stay logged in over multiple sessions. This storage is required for our website to function
            correctly and by agreeing to this privacy policy you agree to this storage being used.
          </p>
          <p className="text-3xl mb-8 mt-10 flex flex-col items-center ">Service Providers</p>

          <p>We may employ third-party companies and individuals due to the following reasons:</p>
          <div className="flex flex-col items-center">
            <ul className="list-disc dark:text-gray-400 mt-5 mb-7">
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
          </div>
          <p>We want to inform our Service users that these third parties have access to your Personal Information. The
            reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or
            use the information for any other purpose.</p>

          <p>On this website services from Google and Cleverreach are being used. To see the terms of service of
            third-parties click on the following link.</p>
          <p className="text-blue-500 mt-10 flex flex-col items-center"><a href="https://policies.google.com/privacy">Google
            Privacy Policy</a> </p>
          <p className="text-blue-500 mt-3 mb-8 flex flex-col items-center"><a
            href="https://www.cleverreach.com/en/privacy-policy/">Cleverreach Privacy Policy</a> </p>
          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Security</p>

          <p>We value your trust in providing us your Personal Information, thus we are striving to use
            commercially
            acceptable means of protecting it. But remember that no method of transmission over the
            internet, or
            method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute
            security.
          </p>

          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Links to Other Sites</p>

          <p>Our Service may contain links to other sites. If you click on a third-party link, you will be
            directed to
            that site. Note that these external sites are not operated by us. Therefore, we strongly advise
            you to
            review the Privacy Policy of these websites. We have no control over, and assume no
            responsibility for the
            content, privacy policies, or practices of any third-party sites or services.</p>

          <p>Children's Privacy</p>

          <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal
            identifiable
            information from children under 13. In the case we discover that a child under 13 has provided
            us with
            personal information, we immediately delete this from our servers. If you are a parent or
            guardian and you
            are aware that your child has provided us with personal information, please contact us so that
            we will be
            able to do necessary actions.</p>

          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Changes to This Privacy Policy</p>

          <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page
            periodically
            for any changes. We will notify you of any changes by posting the new Privacy Policy on this
            page. These
            changes are effective immediately, after they are posted on this page.</p>

          <p className="text-3xl mb-8 mt-10 flex flex-col items-center">Contact Us</p>

          <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact
            us under: <br /><br />
            <a className="text-blue-500 flex justify-center" href="mailto:info@ktr-solutions.com">
              info@ktr-solutions.com</a>
          </p>
        </div>
      </section>

      <Footer />
    </>
  );
}