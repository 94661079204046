import React from 'react';

const Solution: React.FC = () => {
return (
<section className="pt-16 pb-24 md:pt-20 md:pb-48">
  <div className="container mx-auto text-center max-w-7xl px-4">
    <h2 className="text-3xl md:text-4xl font-bold text-gray-800">
      Transform the Way You Manage Your Music Collection
    </h2>
    <p className="text-base md:text-lg mt-6 max-w-xl mx-auto text-gray-600">
      Empower your music catalog management with KTR Studio's intuitive workflows, enhancing collaboration without the
      hassle.
    </p>

    <div className="mt-16 md:mt-20 grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-16">
    <div>
        <img src="img/Reminder.svg" alt="Clean AI-Assisted Workflows Icon" className="h-32 mx-auto" />
        <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mt-6">Simplified Submission Workflow</h3>
        <p className="mt-4 md:mt-6 text-gray-600">
        Our platform uses AI to streamline track submissions, metadata tagging, and approvals, cutting the time to add new tracks.
        </p>
      </div>
      <div>
        {/* Improved Communication Image */}
        <img src="img/Work together.svg" alt="Improved Communication Icon" className="h-32 mx-auto " />
        <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mt-6">Improved Collaboration</h3>
        <p className="mt-4 md:mt-6 text-gray-600">
          With KTR Studio's enhanced submission process, labels and artists benefit from streamlined communication and
          improved collaboration</p>
      </div>
      

      <div>
        {/* Seamless Integrations Image */}
        <img src="img/Integrate.svg" alt="Seamless Integrations Icon" className="h-32 mx-auto" />
        <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mt-6">Data Management and Export</h3>
        <p className="mt-4 md:mt-6 text-gray-600">
        KTR Studio integrates with major platforms like Harvest Media and CadenzaBox, ensuring effortless music delivery to the right audiences.
        </p>
      </div>



    </div>

  </div>
</section>
);
};

export default Solution;