// TODO: export svg
export function Logo40() {
    return (
      <div className="bg-indigo-600 rounded-3xl w-40 h-40 font-extrabold text-white text-7xl text-center items-center flex justify-center">
        <div className="flex items-center">
          <div className="-mr-5 mt-5">K</div>
          <div className="text-9xl">T</div>
          <div className="-ml-5 mt-5">R</div>
        </div>
      </div>
    );
  }
  
  export function Logo40Scaled20() {
    return (
      <div className="bg-indigo-600 rounded-3xl w-40 h-40 font-extrabold text-white text-7xl text-center items-center flex justify-center scale-50">
        <div className="flex items-center">
          <div className="-mr-5 mt-5">K</div>
          <div className="text-9xl">T</div>
          <div className="-ml-5 mt-5">R</div>
        </div>
      </div>
    );
  }
  
  export function Logo20() {
    return (
      <div className="bg-indigo-600 rounded-2xl w-20 h-20 font-extrabold text-white text-3xl text-center items-center flex justify-center">
        <div className="flex items-center">
          <div className="-mr-2 mt-2">K</div>
          <div className="text-6xl">T</div>
          <div className="-ml-2 mt-2">R</div>
        </div>
      </div>
    );
  }
  
  export function Logo20Scaled10() {
    return (
      <div className="bg-indigo-600 rounded-2xl w-20 h-20 font-extrabold text-white text-3xl text-center items-center flex justify-center scale-50">
        <div className="flex items-center">
          <div className="-mr-2 mt-2">K</div>
          <div className="text-6xl">T</div>
          <div className="-ml-2 mt-2">R</div>
        </div>
      </div>
    );
  }
  
  export default function Logo40Solutions() {
    return (
      <div className="flex flex-col items-center">
        <Logo40 />
        <h2 className="font-extrabold text-5xl mt-2">Solutions</h2>
      </div>
    );
  }