import useScript from "../hooks/useScript";
import { useState } from "react";

export default function NewsletterForm2() {
  useScript("/cleverreach.js");
  useScript("https://www.google.com/recaptcha/api.js");
  const [inputfocus, inputnofocus] = useState(true)
  const focus = () => {
    inputnofocus(false)
  }
  return (
    <>
      <div className="flex justify-center">
        <form
          className="layout_form cr_form cr_font font-sans rounded-lg p-4 text-center"
          action="https://seu2.cleverreach.com/f/323790-328788/wcs/"
          method="post"
          target="_blank"
        >
          <div className="flex justify-center">
            <div className="cr_body cr_page cr_font formbox">
              <h2 className="font-sans font-bold break-normal mb-5 text-xl md:text-3xl">Subscribe to our Newsletter</h2>
              <h3 className="font-sans font-bold break-normal text-gray-600 text-sm md:text-base">Get informed about the newest features of KTR Studio <br /> Join our
                newsletter now!</h3>
              <div className="non_sortable"></div>

              <div className="editable_content ">
                <div className="w-full text-center pt-4">
                  <div
                    id="7351651"
                    //  rel="email"
                    className="cr_form-component cr_form-component--email cr_ipe_item ui-sortable musthave"
                  >
                    <div className="cr_form-inputgroup cr_form-inputgroup--typeemail max-w-xl mx-auto p-1 pr-0 flex flex-wrap items-center">
                      <input
                        className="cr_form-input sm:text-sm flex-1 mt-4 appearance-none border border-gray-400 rounded shadow-md p-3 text-gray-600 mr-2 focus:outline-none"
                        type="email"
                        id="text7351651"
                        name="email"
                        placeholder="name@example.com"
                        onFocus={focus}
                      />
                    </div>
                  </div>


                  <div
                    id="7351653"
                    // rel="button"
                    className="flex justify-center cr_form-component cr_form-component--submit cr_ipe_item ui-sortable  submit_container"
                  >
                    <button type="submit" className="cr_form-block cr_button flex-1 mt-4 block md:inline-block appearance-none bg-indigo-500 text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-indigo-400">
                      Subscribe
                    </button>

                  </div>
                  <div
                    id="7351655"
                    // rel="recaptcha"
                    className="cr_ipe_item ui-sortable musthave"
                  >
                    <br />
                    <div
                      id="recaptcha_v2_widget"
                      className={inputfocus ? "g-recaptcha absolute invisible" : "g-recaptcha visible"}
                      data-theme="light"
                      data-size="normal"
                      data-sitekey="6Lfhcd0SAAAAAOBEHmAVEHJeRnrH8T7wPvvNzEPD"
                    ></div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>







    </>
  );
}
