import { useEffect } from 'react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import CallToAction from '../components/CTA';

const people = [
  {
    name: 'Justin Kater',
    role: 'Co-Founder / CEO',
    imageUrl: '../img/justin.jpg',
    bio: 'Justin combines his expertise in music production with advanced skills in AI and cloud infrastructure. His experience in developing generative AI platforms and optimizing data management systems equips him to bring innovative technology to the music production industry.',
    linkedinUrl: 'https://www.linkedin.com/in/justin-kater-21059a210/',
    githubUrl: 'https://github.com/justin-kater', 
    email:"justin.kater@ktr-solutions.com",
  },
  {
    name: 'Colin Kater',
    role: 'Co-Founder / CTO',
    imageUrl: '../img/colin.jpg',
    bio: 'Colin, an experienced software architect, specializes in creating scalable SaaS applications. His proficiency in full-stack development and platform engineering ensures the delivery of robust, efficient, and user-friendly tools designed for the specific needs of music production labels.',
    linkedinUrl: 'https://www.linkedin.com/in/colin-kater/',
    githubUrl: 'https://github.com/colin-kater', 
    email:"colin.kater@ktr-solutions.com",
  },
]

function Example() {
  return (
    <div className="py-16 md:py-16">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-5">
        <div className="max-w-md xl:col-span-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">About KTR Studio</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          KTR Studio was initiated in 2023 to address the challenges faced by production music labels in the manual handling of music submission and tagging. Recognizing the inefficiencies in these processes, we set out to create a more streamlined, digital solution with selected AI features.<br/><br/>
          To ensure we developed a tool that truly meets industry needs, we partnered with a well-established production music label and collaborated with an Audio Engineering University to integrate cutting-edge insights into our product. Our goal is to revolutionize the music submission process, making it faster, easier, and more accurate for music professionals.<br/><br/>
          KTR Studio has been developed by KTR Solutions. If you’re interested in digitalizing your own processes with AI, visit us at <a className='text-indigo-500 font-semibold' href="https://ktr-solutions.com">ktr-solutions.com</a>
          </p>
        </div>
        <ul className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
          {people.map((person) => (
            <li key={person.name} className="flex flex-col gap-10 pt-12 sm:flex-row">
              <img alt="" src={person.name === "Justin Kater" ? require('../img/justin.jpg') : require('../img/colin.jpeg')} className="w-52 h-72 flex-none rounded-2xl object-cover" />
              <div className="max-w-xl flex-auto">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
                <p className="text-base leading-7 text-gray-600">{person.role}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">{person.bio}</p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                  {person.email.split('').map((char, index) => (
                    <span key={index}>{char}</span>
                  ))}
                </p>
                <ul className="mt-6 flex gap-x-6">
                  <li>
                    <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                      <span className="sr-only">LinkedIn</span>
                      <svg fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" className="h-5 w-5">
                        <path
                          d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default function About() {
  useEffect(() => {
    document.title = "KTR Studio - About";
  }, []);

  return (
    <>
      <Header />
      <div className="bg-gray-50 overflow-hidden">
        <Example />

        <CallToAction />
        {/* Footer Section */}
        <div className="lg:w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}
