import { motion } from 'framer-motion';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { GraphQLClient, gql } from 'graphql-request';
import { useEffect, useState } from 'react';
import BlogPostSection from './BlogPostSection';

// Define TypeScript interfaces for the post data structure
interface Author {
  name: string;
  role: string;
  imageUrl: string;
}

interface Category {
  title: string;
  href: string;
}

interface Tag {
  id: string;
  name: string;
}

interface Post {
  id: string;
  title: string;
  href: string;
  description: string;
  date: string;
  datetime: string;
  author: Author;
  tags: Tag[];
}

interface CategorizedPosts {
  documentation: Post[];
  musicproduction: Post[];
}

// Fetching the blog posts from Contentful using GraphQL
async function blogquery(): Promise<Post[]> {
  const endpoint = 'https://graphql.contentful.com/content/v1/spaces/b8exbjlcecvz';
  const queryDocu = gql`
  query GetBlogPostByUrlName{
      singlePostCollection(limit:50, order:[date_DESC]){
          items{
            title
            description
            urlName
            date
            contentfulMetadata {
              tags {
                  id
                  name
              }
            }
          }
        }
      }
  `
  const query = gql`
  query GetBlogPostByUrlName{
      musicPostCollection(limit:50, order:[date_DESC]){
          items{
            title
            description
            urlName
            date
            contentfulMetadata {
              tags {
                  id
                  name
              }
            }
          }
        }
      }
  `

  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      Authorization: 'Bearer Y9k68HidHsj7hfIfxud23KXx6sjRnOZX8NTlM92rgwc',
    },
  });

  const Musicdata = await graphQLClient.request(query);
  const Docudata = await graphQLClient.request(queryDocu);
  const data = [...Musicdata.musicPostCollection.items.map((item: any) => ({...item, type: 'musicPost'})), ...Docudata.singlePostCollection.items.map((item: any) => ({...item, type: 'singlePost'}))]
  
  console.log(data)
  return data.map((item: any) => ({
    id: item.urlName,
    title: item.title,
    href: item.type === 'musicPost' ? `/blog/music/post?id=${item.urlName}` : `/blog/docu/post?id=${item.urlName}`,
    description: item.description,
    date: item.date || 'Unknown date',
    datetime: item.date ? new Date(item.date).toISOString() : '',
    category: { title: item.category || 'General', href: '#' },
    author: {
      name: item.author?.name || 'Unknown author',
      role: item.author?.role || 'Author',
      imageUrl: item.author?.imageUrl || 'https://via.placeholder.com/256',
    },
    tags: item.contentfulMetadata.tags || [],
  }));
}
export default function MusicBlog() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [posts, setPosts] = useState<{ documentation: Post[], musicproduction: Post[] }>({ documentation: [], musicproduction: [] });

  useEffect(() => {
    blogquery()
      .then((data) => {
        const categorizedPosts = data.reduce<CategorizedPosts>((acc, post) => {
          if (post.tags.some(tag => tag.id === "documentation")) {
            acc.documentation.push(post);
          } else if (post.tags.some(tag => tag.id === "musicProduction")) {
            acc.musicproduction.push(post);
          }
          return acc;
        }, { documentation: [], musicproduction: [] });
  
        setPosts(categorizedPosts);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (isLoading) return <span>Loading...</span>;

  return (
    <div className="bg-gray-50">
      <Header />
      <div className="py-16 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <BlogPostSection
            title="KTR Studio Documentation"
            description="Everything you need to know about the platform, including onboarding, usage, and features."
            posts={posts.documentation}
          />
          <BlogPostSection
            title="Music Production Blog"
            description="All about music production, tips and tricks to help you achieve your goals."
            posts={posts.musicproduction}
            className="mt-40"
          />
          {/* TODO: Pagination */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

