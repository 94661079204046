import React from 'react';

const CallToAction: React.FC = () => {
return (
<div className="">
  <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
    <div className="mx-auto max-w-2xl text-center">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Elevate Your Music Management.
        <br />
        Sign up today and take control of your music catalog workflow.
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
        Discover how KTR Studio can simplify your music catalog management, improve collaboration, and automate your
        workflow.
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <button onClick={()=> window.location.href = `https://app.ktr.studio/signup${window.location.search}`}
          className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm
          hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
          focus-visible:outline-blue">
          Sign up Now
        </button>
        {/* <a href="#challenges" className="text-sm font-semibold leading-6 text-gray-900">
          Learn more <span aria-hidden="true">→</span>
        </a> */}
      </div>
    </div>
  </div>
</div>
);
};

export default CallToAction;