import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function Thankyou() {

useEffect(() => {
document.title = "KTR Studio - Thanks for signing up!"
}, []);
return (
<>

  <div className="h-screen">
    <Header />
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-center bg-grey-50">
        <div className="flex flex-col">
          <div className="flex flex-col mt-8">
            <div className="container max-w-7xl px-4">
              <div className="flex flex-wrap justify-center text-center mb-24">
                <div className="w-full px-4">
                  <h1 className="text-gray-900 text-4xl font-bold md:mb-6">
                    Thanks for signing up!
                  </h1>
                </div>
              </div>
              <div className="flex flex-wrap justify-center">
                <div className="w-5/6 md:w-7/12 text-center md:mt-24">
                  <h2 className="text-gray-900 text font-bold mb-24 md:mb-48">
                    Thank you for signing up to our Newsletter.
                    Your Email is verified now!<br /> <br />
                    We will notify you as soon as our software is available.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</>
)
}