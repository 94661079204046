import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Verifymail() {
  useEffect(() => {
    document.title = "KTR Studio - Verify your Email"
    const script = document.createElement("script")
    script.innerHTML = "console.log('asd');gtag('event', 'conversion', {'send_to': 'AW-10926362464/oMOtCJ-A7cUDEOCejNoo'});"
    script.async = true
    document.body.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  }, [])
return (
<>
<div className="h-screen">
<Header />
  <div className="flex flex-col justify-between">
    <div className="flex items-center justify-center bg-grey-50">
      <div className="flex flex-col">
        <div className="flex flex-col mt-8">
          <div className="container max-w-7xl px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full px-4">
                <h1 className="text-gray-900 text-4xl font-bold md:mb-6">
                  Verify Your Email Adress
                </h1>
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
            <div className="w-5/6 md:w-7/12 text-center md:mt-24">
              <h2 className="text-gray-900 text font-bold mb-24 md:mb-48">
               Thank you for signing up to our Newsletter. <br/> <br/>Please verify your Email by ckicking on the Link in the Email we just sent you!<br/>
               <br/>Make sure you check your spam folder if you cant find the email.
              </h2>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  </div>
</>
)
}