import React from 'react';

const steps = [
  {
    name: 'Sign up and Test',
    description: 'Sign up to KTR Studio and explore the features with a free trial, getting familiar with the platform.',
    icon: 'img/Login.svg',
  },
  {
    name: 'Import Your Music Catalog',
    description: 'Easily import your existing music catalog into KTR Studio, including all metadata and files.',
    icon: 'img/Playlist.svg',
  },
  {
    name: 'Request Music Submissions',
    description: 'Ensure better collaboration by inviting artists to submit their tracks through KTR Studio.',
    icon: 'img/Share opinion.svg',
  },
  {
    name: 'Sort Files and Publish',
    description: 'Sort submitted tracks into albums and publish them directly on CadenzaBox or Harvest Media.',
    icon: 'img/Online Connection.svg',
  },
];

const Challenge: React.FC = () => {
  return (
    <div className="pt-20 pb-14">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Catalog Management on Autopilot</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl">
          KTR Studio ensures a smooth workflow, guiding you from music submission to metadata tagging and publishing.          </p>
        </div>
        <div className="mx-auto mt-20 max-w-2xl lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
            {steps.map((step) => (
              <div key={step.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-20 flex mx-auto items-center justify-center rounded-l">
                    <img src={step.icon} alt="" className="h-40 text-white" />
                  </div>
                  {step.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{step.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Challenge;
