import React from 'react';

const Hero: React.FC = () => {
return (
<div className="">
    <div className="relative isolate overflow-hidden pt-8">
        <div className="mx-auto max-w-7xl px-6 x lg:pt-32 pb-24 sm:pb-40">
            <div
                className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:gap-x-8">
                <h1
                    className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                    Redefine Catalog Management with KTR Studio
                </h1>
                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 order-2 lg:order-1">
                    <p className="text-lg leading-8 text-gray-600">
                    Simplify your music catalog management with KTR Studio, automating key workflows and freeing up more time for creativity.
                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                        <button onClick={() => window.location.href = `https://app.ktr.studio/signup${window.location.search}`}
                            className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue">
                            Sign up Now
                        </button>
                        <a href="#challenges" className="text-sm font-semibold leading-6 text-gray-900">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <div className='order-1 lg:order-2'>
                <img alt="Music management automation illustration" src="/img/Audio Player.svg"
                    className="w-2/3 rounded-2xl object-cover lg:-mt-48 mt-8 mx-auto my-auto lg:max-w-none" />
                    </div>
            </div>
        </div>
    </div>
</div>
);
};

export default Hero;
