import React from "react"
import Landing from "../routes/Landing";
import Contact from "../routes/Contact";
import Privacy from "../routes/Privacy";
import TermsOfService from "../routes/TermsOfService";
import NotFound from "../routes/NotFound";
import About from "../routes/About";
import Imprint from "../routes/Imprint";
import Thankyou from "../routes/Thankyou";
import Verify from "../routes/Verifymail";
import MusicBlog from "../routes/blog/Blog";
import DocuBlogPost from "../routes/blog/DocuBlogPost";
import MusicBlogPost from "../routes/blog/MusicBlogPost";

import { AnimatePresence } from "framer-motion"

import {
    Route,
    Routes,
    useLocation
  } from "react-router-dom";

function AnimatedRoutes(){
    const location = useLocation();
    return(
        <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            {/* public  */}
            <Route path="/" element={<Landing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/about" element={<About />} />
            <Route path="/imprint" element={<Imprint />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/blog" element={<MusicBlog />} />               
            <Route path="/blog/docu/post" element={<DocuBlogPost />} />
            <Route path="/blog/music/post" element={<MusicBlogPost />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
        </AnimatePresence>
    );
}

export default AnimatedRoutes;