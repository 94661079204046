import Footer from "../components/Footer";
import Header from "../components/Header";
import { useEffect } from 'react';

export default function About() {

useEffect(() => {
document.title = "KTR Studio - Imprint"
}, []);
return (
<>
  <div className="h-screen">
    <Header />
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-center bg-grey-50">
        <div className="flex flex-col">
          <h1 className="text-3xl mx-auto text-bold my-10">Imprint</h1>
          <div className="flex flex-col mt-8">
            <div className="container max-w-7xl px-4">
              <div className="flex flex-wrap justify-center text-center mb-24">
                <div className="text-xl pt-28">
                  KTR Solutions GmbH<br />
                  Panoramastraße 28 / 1<br />
                  71691 Freiberg am Neckar<br />
                  Baden-Württemberg<br />
                  Germany<br /><br />
                  Phone: (+49) 15736503614<br />
                  Email: info@ktr-solutions.com<br /><br />
                  Registergericht: Amtsgericht Stuttgart <br />
                  Handelsregisternummer: HRB 785755
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</>
)
}