import React from "react";
import { Music, Upload, Package, ExternalLink, Layers, Grid } from "react-feather";

const villains = [
  {
    name: "AI Metadata Tagging",
    description:
      "Our AI recomends tags for your music and generates metadata such as BPM, mood, genre, and more.",
    link: "/blog/post?id=metadata-tagging",
    icon: <Music />, 
  },
  {
    name: "Automated Submission Process",
    description:
      "Streamline communication between label and artists with an easy submission and approval process.",
    link: "/blog/post?id=submission-process",
    icon: <Upload />, 
  },
  {
    name: "CadenzaBox Integration",
    description:
      "Export albums and publish them easily on your website with CadenzaBox, turning your entire catalog into a fully functional store.",
    link: "/blog/post?id=cadenzabox-integration",
    icon: <Package />, 
  },
  {
    name: "Harvest Media Integration",
    description:
      "Reach millions of users worldwide by exporting albums and publishing them directly to the Harvest Media music shop platform",
    link: "/blog/post?id=harvestmedia-integration",
    icon: <ExternalLink />, 
  },
  {
    name: "Easy Album Publishing",
    description:
      "Follow a streamlined workflow to gather all necessary information and export albums in the correct format for publishing on marketplaces.",
    link: "/blog/post?id=easy-album-publishing",
    icon: <Layers />, 
  },
  {
    name: "Multi-Platform Export",
    description:
      "Export and manage your albums across various platforms, ensuring proper format and metadata compliance.",
    link: "/blog/post?id=multi-platform-export",
    icon: <Grid />, 
  },
];

const HowItWorks: React.FC = () => {
  return (
    <div className="overflow-hidden " id="challenges">
      <div className="mx-auto pt-18 pb-20 max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0 text-center lg:text-left">
        <h2 className="text-base font-semibold leading-7 text-indigo-500">
        Master the Art of Effortless Music Management
</h2>
<p className="mt-4 max-w-lg text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
Never use a spreadsheet to store metadata again
</p>
<p className="mt-6 text-lg leading-8 text-gray-600">
Enhance the data quality of your music catalog with an intelligent solution that integrates directly into your publishing platform.
</p>
        </div>
        <div className="mt-20 grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
          {villains.map((villain) => (
            <div key={villain.name} className="flex flex-col">
              <div className="text-blue-500 text-4xl">
                {villain.icon} {/* Feather icon */}
              </div>
              <dt className="mt-4 text-base font-semibold leading-7 text-gray-900">
                {villain.name}
              </dt>
              <dd className="mt-1 text-base leading-7 text-gray-600">
                {villain.description}
              </dd>
              <a
                href={villain.link}
                className="mt-2 text-indigo-600 text-sm font-semibold leading-6"
              >
                Discover more →
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
