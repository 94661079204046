export default function Footer() {
  return (
    <footer className="">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <a href="/">
              <div className=" flex flex-col items-center">
                <div
                  className="flex h-20 w-20 items-center justify-center rounded-2xl bg-indigo-600 text-center text-3xl font-extrabold text-white">
                  <div className="flex items-center">
                    <div className="-mr-2 mt-2">K</div>
                    <div className="text-6xl">T</div>
                    <div className="-ml-2 mt-2">R</div>
                  </div>
                </div>
                <h2 className="mt-2 text-center text-2xl font-extrabold text-slate-700">Studio</h2>
              </div>
            </a>

          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-4">
            <div>
              <p className="text-lg md:text-md">Company</p>
              <nav className="mt-2 md:mt-4 flex flex-col space-y-2 text-lg md:text-sm text-gray-500">
                <a className="hover:opacity-75" href="/about"> About </a>
              </nav>
            </div>
            <div>
              <p className="text-lg md:text-md">Helpful Links</p>
              <nav className="mt-2 md:mt-4 flex flex-col space-y-2 text-lg md:text-sm text-gray-500">
                <a className="hover:opacity-75" href="/contact"> Contact </a>
              </nav>
            </div>
            <div>
              <p className="text-lg md:text-md">Legal</p>
              <nav className="mt-2 md:mt-4 flex flex-col space-y-2 text-lg md:text-sm text-gray-500">
                <a className="hover:opacity-75" href="/privacy"> Privacy Policy </a>
                <a className="hover:opacity-75" href="/tos"> Terms &amp; Conditions </a>
                <a className="hover:opacity-75" href="/imprint"> Imprint </a>
              </nav>
            </div>
          </div>
        </div>
        <p className="text-xs mt-2 md:mt-6 ml-28 text-gray-800">© 2024 KTR Solutions GmbH</p>
      </div>
    </footer>
  );
}