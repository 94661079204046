import Footer from "../components/Footer";
import Header from "../components/Header";
import NewsletterForm2 from "../components/NewsletterForm2";
import Hero from "../components/Hero";
import { PrimaryFeatures } from '../components/PrimaryFeatures'

import { JsonLd } from "react-schemaorg";
import { Organization } from "schema-dts";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { motion } from 'framer-motion'
import Challenge from "../components/Challenge";
import Solution from "../components/Solution";
import HowItWorks from "../components/HowItWorks";
import CallToAction from "../components/CTA";
export default function Landing() {

  const [scroll, setScroll] = useState(false);


  const [searchParams] = useSearchParams();


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  return (
    <>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.3 } }} exit={{ opacity: 0, transition: { duration: 0.3 } }}>
        <div className="flex flex-col screen-h justify-between bg-gray-50" id="hero-page">
          <Header />
          <div className="">
            <Hero />
          </div>
        <div className="flex flex-col justify-center -mt-1" id="feat3-page">
          <Solution />
        </div>
        <div className="flex flex-col justify-center -mt-1" id="feature-page">
          <HowItWorks />
        </div>
        <div className="flex flex-col justify-center -mt-1" id="feat2-page">
          <Challenge />
        </div>
        <div className="flex flex-col justify-center" id="Newsletter-page">
          <div className="mx-auto pt-2 md:pt-4 lg:pt-10 px-8 xl:px-12">
            <CallToAction />
          </div>
          <div className="w-full">
            <Footer />
          </div>
        </div>
        </div>
      </motion.div>
    </>
  )
};